import { Provider } from 'react-redux'
import store from './redux/store'
import Router from './router/Router'
import Footer from './footer/Footer'

const App = () => (
    <Provider store={store}>
        <Router />
        <Footer />
    </Provider>
)

export default App
