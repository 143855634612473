import {
    SELECT_MENU_ITEM,
    TOGGLE_TOP_MENU_VISIBILITY
} from './actions'

// match MENU_ITEMS in actions
const initialPage = (() => {
    const initialPathname = window.location.pathname

    switch(initialPathname) {
        case '/':
            return 'HOME'
        case '/events':
            return 'UPCOMING_EVENTS'
        case '/contact':
            return 'CONTACT'
        case '/about':
            return 'ABOUT'
        default:
            return 'HOME'
    }
})()

const initialState = {
    itemSelected: initialPage,
    topMenuIsVisible: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_MENU_ITEM: {
            return {
                ...state,
                itemSelected: action.payload
            }
        }
        case TOGGLE_TOP_MENU_VISIBILITY: {
            return {
                ...state,
                topMenuIsVisible: !state.topMenuIsVisible
            }
        }
        default:
            return state
    }
}

export default reducer
