import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HomePage from '../home/HomePage'
import AboutPage from '../about/AboutPage'
import ContactPage from '../contact/ContactPage'
import TopMenu from '../menu/TopMenu'
import LeftMenu from '../menu/LeftMenu'
import EventsPage from '../events/EventsPage'
import StickyRsvp from './StickyRsvp'
import Config from '../config'

const RouterPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <BrowserRouter>
            <div className="flex md:items-center min-h-[100vh] bg-yellow-300 \
                flex-wrap md:flex-nowrap">
                { Config.broadcastNextEvent && <StickyRsvp /> }
                <div className="py-4 md:w-1/4 lg:w-1/2 text-gray-800 select-none">
                    <div className="hidden md:block fixed left-0 lg:left-1/4 top-[40vh]">
                        {<LeftMenu />}
                    </div>
                    <div className="md:hidden">
                        {<TopMenu />}
                    </div>
                </div>
                <div className="leading-loose p-8 overflow-auto w-full md:w-3/4 \
                    lg:w-1/2 md:pt-12 max-w-[1200px] md:pt-24">
                    <Routes>
                        <Route path="/">
                            <Route index element={ <HomePage /> } />
                            <Route path="about" element={ <AboutPage /> }/>
                            <Route path="contact" element={ <ContactPage /> }/>
                            <Route path="events" element={ <EventsPage /> }/>
                        </Route>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    )
}

export default RouterPage
