import { createSelector } from 'reselect'

const selectMenu = state => state.menu

export const selectSelectedMenuItem = createSelector(
    selectMenu,
    menu => menu.itemSelected
)

export const selectTopMenuVisibility = createSelector(
    selectMenu,
    menu => menu.topMenuIsVisible
)
