const Footer = () => {
    const year = (new Date()).getFullYear()

    return (
        <div className="bg-rose-400 text-white py-4 w-full text-center">
            <p>&copy; { year } Curated with love by Ci Hui Association</p>
        </div>
    )
}

export default Footer
