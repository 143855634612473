import PropTypes from 'prop-types'
import './hamburger.css'
import { connect } from 'react-redux'
import { toggleTopMenu } from './ducks/actions'
import { selectTopMenuVisibility } from './ducks/selectors'

const Hamburger = ({ isVisible, toggleMenuVisibility }) => {
    return (
        <button className={`hamburger hamburger--collapse ${
            isVisible ? 'is-active' : '' }`}
            type="button"
            onClick={ toggleMenuVisibility }>
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>
    )
}

Hamburger.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    toggleMenuVisibility: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isVisible: selectTopMenuVisibility(state)
})

const mapDispatchToProps = dispatch => ({
    toggleMenuVisibility: (item) => dispatch(toggleTopMenu(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Hamburger)
