import PropTypes from 'prop-types'

const Gallery = ({ items }) => {
    return (
        <div className="flex flex-wrap mb-2 \
            overflow-hidden rounded-md p-2 \
            bg-white bg-opacity-40">

            {items.map((item, i) =>
                <img key={i} className="p-1 rounded-md \
                    w-1/2 md:w-1/3 \
                    object-contain object-center \
                    even:pl-0 \
                    md:even:px-0 \
                    [&:nth-child(n+3)]:pt-0 \
                    md:[&:nth-child(n+4)]:pt-0" src={item} loading="lazy"/>
            )}
        </div>
    )
}

Gallery.propTypes = {
    // list of urls
    items: PropTypes.arrayOf(PropTypes.string)
}

export default Gallery
