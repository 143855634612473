import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectMenuItem } from '../menu/ducks/actions'
import Config from '../config'

const HomePage = ({ navigateToEventsPage }) => {
    useEffect(() => {
        document.title = "Ci Hui Association"
    })

    return (
        <div className="">
            <h1 className="text-right text-6xl font-bold mb-8 text-rose-400 font-cursive">Hello!</h1>
            <hr className="my-8 border-white" />
            <div className="mb-24 text-gray-600 text-lg">
                <p className="mb-8 leading-loose">
                    Greetings from Ci Hui Association, we are a not-for-profit
                    organisation helping our members to learn more about the
                    philosophies and practices of Confucianism along with other
                    theological teachings.
                </p>
                <p className="mb-8 leading-loose">
                    We are hosting a series of plant-based and vegetarian food tastings across
                    a wide range of international cuisines. Our themes include
                    Western, Japanese, Taiwanese, Vietnamese, Cambodian,
                    and more.
                </p>
                <p className="mb-8 leading-loose">
                    We humbly invite you to join us in these events, and explore
                    for yourselves the various delicacies our local chefs have to offer!
                </p>
                <p className="leading-loose mb-4">
                    For more details, check out our:
                </p>
                <ul className="px-3">
                    <li className="pl-3 border-l-2 border-rose-400 mb-3">
                        <a className="cursor-pointer hover:text-rose-400"
                            href="https://facebook.com/cihuiassociation" target="new">
                            <img className="w-[20px] inline-block"
                                src="/icons/facebook.png" alt="facebook icon" /> facebook
                        </a>
                    </li>
                    <li className="pl-3 border-l-2 border-rose-400 mb-3">
                        <a className="cursor-pointer hover:text-rose-400"
                            href="https://instagram.com/cihuiassociation" target="new">
                            <img className="w-[20px] inline-block"
                                src="/icons/instagram.png" alt="instagram icon" /> instagram
                        </a>
                    </li>
                </ul>
                { Config.broadcastNextEvent &&
                <div className="mt-12">
                    <a target="new" href={ Config.nextEventFormUrl }>
                        <button className="bg-rose-400 text-white px-4 py-2 w-full hover:bg-rose-300">
                            <span className="inline-block animate-[bounce_1s_infinite_100ms]">R</span>
                            <span className="inline-block animate-[bounce_1s_infinite_200ms]">S</span>
                            <span className="inline-block animate-[bounce_1s_infinite_300ms]">V</span>
                            <span className="inline-block animate-[bounce_1s_infinite_400ms]">P</span>
                            <span> to upcoming food tasting</span>
                        </button>
                    </a>
                </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    navigateToEventsPage: () => dispatch(selectMenuItem('UPCOMING_EVENTS'))
})

HomePage.propTypes = {
    navigateToEventsPage: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
