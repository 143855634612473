import PropTypes from 'prop-types'
import { Router, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectMenuItem, MENU_ITEMS } from './ducks/actions'
import { selectSelectedMenuItem } from './ducks/selectors'

const LeftMenu = ({ onSelect, selectedItem }) => {
    return (
        <div className="text-xl">
            <ul className="pl-8">
                { Object.keys(MENU_ITEMS).map(item =>
                    item === selectedItem
                        ?
                        <li key={ item } className="block pb-2 mb-4 pl-2">
                            <span className="pb-2 border-b-2 border-rose-400 \
                                text-rose-400">
                                { MENU_ITEMS[item].text }
                            </span>
                        </li>
                        :
                        <li key={ item } className="block pb-2 mb-4">
                            <Link to={ MENU_ITEMS[item].linkTo }>
                                <span className="pl-2 hover:border-l-2 border-rose-400"
                                    onClick={() => onSelect(item)}>
                                    { MENU_ITEMS[item].text }
                                </span>
                            </Link>
                        </li>
                )}
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    selectedItem: selectSelectedMenuItem(state)
})

const mapDispatchToProps = dispatch => ({
    onSelect: (item) => dispatch(selectMenuItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu)
