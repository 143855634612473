import PropTypes from 'prop-types'
import { Router, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectMenuItem, MENU_ITEMS } from './ducks/actions'
import { selectSelectedMenuItem, selectTopMenuVisibility } from './ducks/selectors'
import Hamburger from './Hamburger'

const Top = ({ onSelect, selectedItem, isVisible }) => {
    return (
        <div className="text-xl">
            <div className="">
                <Hamburger />
            </div>
            { isVisible &&
                <ul className="mt-4">
                    { Object.keys(MENU_ITEMS).map(item =>
                        item === selectedItem
                            ?
                            <li key={ item } className="block px-4 pl-3 mx-2 \
                                border-l-2 border-rose-400 mb-4 \
                                text-rose-400">
                                { MENU_ITEMS[item].text }
                            </li>
                            :
                            <li key={ item } className="block px-4 mx-2 \
                                hover:border-l-2 border-rose-400 mb-4">
                                <Link to={ MENU_ITEMS[item].linkTo }>
                                    <span onClick={() => onSelect(item)}>
                                        { MENU_ITEMS[item].text }
                                    </span>
                                </Link>
                            </li>
                    )}
                </ul>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    selectedItem: selectSelectedMenuItem(state),
    isVisible: selectTopMenuVisibility(state)
})

const mapDispatchToProps = dispatch => ({
    onSelect: (item) => dispatch(selectMenuItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Top)
