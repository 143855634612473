import Config from '../config'

const StickyRsvp = () => {
    return (
        <div className="bg-rose-400 px-8 py-2 w-full md:fixed md:top-0 text-center">
            <a className="relative" target="new" href={ Config.nextEventFormUrl }>
                <button className="bg-rose-400 border border-rose-300 text-white px-4 py-2 hover:bg-rose-300">
                    RSVP { Config.nextEventDateString }
                </button>
                <span className="absolute -top-3 -right-1 flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
                </span>
            </a>
        </div>
    )
}

export default StickyRsvp
