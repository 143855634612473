import { combineReducers } from 'redux'
import home from '../home'
import menu from '../menu'

const appReducer = combineReducers({
    home,
    menu
})

const rootReducer = (state, action) => appReducer(
    state,
    action
)

export default rootReducer
