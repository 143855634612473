import SingleEventSection from './SingleEventSection'
import Destination from './Destination'
import Config from '../config'

const UpcomingEventsSection = () => {
    return (
        <div className="">
            <h1 className="text-right text-4xl font-bold mb-8 text-rose-400">
                Upcoming
                <img className="inline-block rounded-md w-36 mx-2" src="/icons/plant-based_rose-400.png" alt="plant-based" />
                Events
            </h1>
            <hr className="my-8 border-rose-400" />

            <SingleEventSection title="Vietnamese" dateString="10:45am Saturday, 15 June 2024">
                <div className="flex flex-wrap mb-8 rounded-md">
                    <img className="rounded-md mb-2 object-cover object-center max-h-[600px] w-full" src="/photos/thumbnails/vietnamese_1.jpg" />
                </div>
                <p className="leading-loose">
                    We end up in <Destination text="Vietnam" /> for our next destination.
                    Vietnamese cuisine emphasises on a cohesive balance of taste, topped with fresh herbs, spices and condiments.
                    Let us indulge in the wide array of flavours Vietnamese cuisine has to offer!
                </p>
                { Config.broadcastNextEvent &&
                <div className="mt-8">
                    <a target="new" href={ Config.nextEventFormUrl }>
                        <button className="bg-rose-400 text-white px-4 py-2 w-full hover:bg-rose-300">
                            RSVP here
                        </button>
                    </a>
                </div>
                }
            </SingleEventSection>

            { /*
            <SingleEventSection title="Taiwanese" dateString="TBD 2024">
                <div className="flex flex-wrap mb-8">
                    <img className="rounded-md mb-2" src="/photos/thumbnails/水煎包.jpg" />
                </div>
                <p className="leading-loose">
                    We fly over to <Destination text="Taiwan" />.
                    What differences are there between Taiwanese and
                    other Asian cuisines? Join us to find out.
                </p>
            </SingleEventSection>
            */ }
        </div>
    )
}

export default UpcomingEventsSection
