import { useEffect } from 'react'

const ContactPage = () => {
    useEffect(() => {
        document.title = "Contact Ci Hui Association"
    })

    return (
        <div className="text-right">
            <h1 className="text-4xl font-bold mb-8 text-rose-400">Get in Touch</h1>
            <hr className="my-8 border-white" />
            <div className="text-lg text-gray-600 mb-4">
                <div className="">
                    <p>cihuiassociation@gmail.com</p>
                </div>
                <div className="mt-8">
                    <p>7 Wardale Road</p>
                    <p>Springvale South</p>
                    <p>VIC 3172</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.3032283000534!2d145.15762961312595!3d-37.970052242872015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6149f7a4ab317%3A0xe77e18227d01a982!2s7%20Wardale%20Rd%2C%20Springvale%20South%20VIC%203172!5e0!3m2!1sen!2sau!4v1689329585992!5m2!1sen!2sau"
                        className="border-0 mt-8 w-full"
                        width="" height="450"
                        allowFullScreen={ false } loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" />
                </div>
                <div className="mt-16">
                    <p>See you on our socials!</p>
                </div>
                <div className="mt-8 flex items-end flex-wrap justify-end">
                    <div className="w-[50px] h-[50px] mr-4">
                        <a href="https://facebook.com/cihuiassociation" target="new">
                            <img src="/icons/facebook.png"
                                alt="facebook icon" />
                        </a>
                    </div>
                    <div className="w-[50px] h-[50px]">
                        <a href="https://instagram.com/cihuiassociation" target="new">
                            <img src="/icons/instagram.png"
                                alt="instagram icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage
