const namespace = "menu"

export const MENU_ITEMS = {
    HOME: { text: 'Home', linkTo: '/' },
    UPCOMING_EVENTS: { text: 'Events', linkTo: '/events' },
    ABOUT: {text: 'About', linkTo: '/about' },
    CONTACT: { text: 'Contact', linkTo: '/contact' },
}

export const SELECT_MENU_ITEM = `${namespace}/SELECT_MENU_ITEM`
export const TOGGLE_TOP_MENU_VISIBILITY = `${namespace}/TOGGLE_TOP_MENU_VISIBILITY`

export const selectMenuItem = (item) => (dispatch, getState) => {
    if (!(item in MENU_ITEMS))
        throw new Error(`${item} cannot be found`)

    dispatch({
        type: SELECT_MENU_ITEM,
        payload: item
    })
}

export const toggleTopMenu = () => ({
    type: TOGGLE_TOP_MENU_VISIBILITY
})
