import PropTypes from 'prop-types'

const SingleEventSection = ({ children, title, dateString }) => {
    return (
        <div>
            <div className="pr-4 border-r-2 border-rose-400">
                <h2 className="text-right text-2xl text-rose-400">{ title }</h2>
                <p className="text-right text-gray-500 mb-8">{ dateString }</p>
            </div>
            <div className="leading-loose text-lg text-gray-600 mb-8">
                { children }
            </div>
            <hr className="my-16 border-rose-400" />
        </div>
    )
}

SingleEventSection.propTypes = {
    title: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired
}

export default SingleEventSection
