import { useEffect } from 'react'

const AboutPage = () => {
    useEffect(() => {
        document.title = "About Ci Hui Association"
    })

    return (
        <div className="">
            <h1 className="text-right text-4xl font-bold mb-8 text-rose-400">What We Do</h1>
            <hr className="my-8 border-white" />
            <p className="text-lg text-rose-400 mb-8">
                The purposes of our association are to:
            </p>
            <ul className="text-gray-600">
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Spread the philosophies and practices of Confucianism,
                    Taoism, Buddhism, Christianity and Islam
                </li>
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Enhance the truth of Great Tao
                </li>
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Propagate moral and intellectual principles to members
                    and others on the teaching of great saints leading to the
                    purification of human nature
                </li>
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Help people to re-connect with their inner-selves and enhance
                    the stability of our society
                </li>
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Promote vegetarianism and show respects to all life on earth
                </li>
                <li className="mb-8 pl-4 border-l-2 border-rose-400">
                    Help people to re-connect with families through studying
                    traditional Chinese values
                </li>
            </ul>
        </div>
    )
}

export default AboutPage
